import React from 'react';
import './warning.css';

function Warning() {
  return (
    <div className="warning">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <g>
          <path d="M26.7847,29.9971H5.21a3.21,3.21,0,0,1-2.8706-4.6455L13.1265,3.7773a3.21,3.21,0,0,1,5.7417,0L29.6558,25.3516a3.21,3.21,0,0,1-2.8711,4.6455ZM15.9971,4.0029a1.1865,1.1865,0,0,0-1.0816.669L4.1279,26.2461A1.2095,1.2095,0,0,0,5.21,27.9971H26.7847a1.21,1.21,0,0,0,1.082-1.751L17.0791,4.6719A1.1878,1.1878,0,0,0,15.9971,4.0029Z" />
          <path d="M16,26.5a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,16,26.5Zm0-2.001v0Z" />
          <path d="M17.6846,11.335A1.0013,1.0013,0,0,0,16.9375,11h-1.875a1,1,0,0,0-.9932,1.1162l.9375,8a1,1,0,0,0,1.9864,0l.9375-8A1.0009,1.0009,0,0,0,17.6846,11.335Z" />
        </g>
      </svg>
    </div>
  );
}

export default Warning;
